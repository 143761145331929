import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import ScroolTop from './../components/scrolltop'
import { removePre } from './../util/common';
// import SEO from "../components/seo";


class PostTemplate extends Component {
  render() {
    const data = this.props.data;
    const post = this.props.data.wordpressWpWork;
    //console.log(post);
    // const sharefb = "https://www.facebook.com/sharer/sharer.php?u=";
    // const shareln = "https://www.linkedin.com/shareArticle?mini=true&url=";
    // const sharetw = "https://twitter.com/intent/tweet?text=";
    // const sharepi = "http://pinterest.com/pin/create/button/?url=";
    
    return (
      <>
          {/* <SEO /> */}
        <div class="page-header">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h5>Portfolio</h5>
                        <h1>{post.title}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="work-detail-section">
            <div className="container">
                <div className="work-detail-inner">
                    <div className="row">
                        <div className="col-lg-12 portfolio-detail-left">
                            <div className="project-overview">
                                {/* <h1>{post.title}</h1> */}
                                <div className="content-description" > 
                                    <div className="col-lg-12">
                                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                                    </div>
                                    {/* <div className="col-md-6">
                                        <p dangerouslySetInnerHTML={{ __html: post.acf.content_column_2 }} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 portfolio-detail-right">
                            <div className="project-detail">
                                <ul>
                                    <li><h4>Client</h4><span>{post.acf.work_top_section.work_right_content[0].work_client}</span></li>
                                    <li><h4>Category</h4><span>{post.acf.work_top_section.work_right_content[0].work_category.name}</span></li>
                                    <li><h4>Date</h4><span>{post.date}</span></li>
                                    <li>
                                        <h4>Share</h4>
                                        <ul>
                                            <li><a href={sharefb + `https://finntia.netlify.com` +post.path}>Fb.</a></li>
                                            <li><a href={shareln + `https://finntia.netlify.com` +post.path}>Tw.</a></li>
                                            <li><a href={sharetw + `https://finntia.netlify.com` +post.path}>Ln.</a></li>
                                            <li><a href={sharepi + `https://finntia.netlify.com` +post.path+`&description=`+post.title}>Pi.</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="portfolio-gallary-sec">
            <div className="container">
                <ul className="gallary-wrap">
                    {post.acf.work_gallery.map((gallery,index) => (
                        <li key={index}>
                            {gallery !== null &&
                                <img src={gallery} alt="Work gallery"/>                                  
                            }
                        </li>
                    ))}                     
                </ul>
            </div>
        </div>
        <div className="portfolio-footer">
            <div className="container">
                <div className="portfolio-footer-inner">
                    <Link to={`/${removePre(data.wordpressPage.link)}`} className="btn back-to-portfolio-btn">
                        Back to everything
                        <span>See all our projects</span>
                    </Link>
                </div>
            </div>
        </div>  
        <ScroolTop />     
      </>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!, $cat: String ) {
    wordpressWpWork(id: { eq: $id }) {
        id
        slug
        title
        content
        path
        date(formatString: "D/MM/YYYY")
        featured_media {
            source_url
          }
        acf {
            work_gallery
            work_short_description
            work_image_alignment
            work_features_list {
              work_feature
            }
            work_top_section {
                work_right_content {
                  work_client
                  work_category {
                    name
                  }
                }
            }
        }
    }
    wordpressPage(wordpress_id: {eq: 17}) {
        link
    }
    allWordpressPost(limit: 3, filter: {categories: {elemMatch: {slug: {eq: $cat}}}, id: {ne: $id}}) {
        edges {
            node {
                id
                title
                wordpress_id
                date(formatString: "l")
                slug
                excerpt
                link
                featured_media {
                    source_url
                }
            }
        }
    }
  }
`
